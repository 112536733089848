@import url(https://fonts.googleapis.com/css?family=Nunito:200,300,400,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900&display=swap);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html {
    font-size: 10px;
}
body {
    font-family: "Nunito", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#swift-sales-widget {
    background-color: transparent;
    z-index: 99999999;
    max-height: 120px;
    position: fixed;
    bottom: 0px;
    border: 0px;
    width: 450px;
    height: 670px;
    max-width: 120px;
    overflow: hidden;
    opacity: 1;
    right: 0px;
}

.swift-sales-widget-integrate{
    width: 100% !important;
    height: 100% !important;
}
#swift-sales-widget-iframe {
    pointer-events: all;
    background: none;
    border: 0px;
    float: none;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

.fancy-scroll {
    overflow: hidden;
    overflow-y: auto;
}

.fancy-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0);
    border-radius: 1rem;
    background-color: inherit;
}
.fancy-scroll::-webkit-scrollbar {
    width: 0.6rem;
    /* background-color: #f5f5f5;*/
}
.fancy-scroll::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0);
    background-color: #89898961;
}
.fancy-scroll.auto::-webkit-scrollbar-thumb {
    background-color: #89898900;
}
.fancy-scroll.auto:hover::-webkit-scrollbar-thumb {
    background-color: #89898961;
}

.smooth-scroll {
    scroll-behavior: smooth;
}

/* #longDriveAgency-iframe {
    height: 670px !important;
} */

main {
    position: relative;
    display: block;
    width: 100%;
    height: 100vh;
}

.chat-widget .chat-widget-btn {
    position: relative;
    float: right;
}

/* Current */
.chat-widget-btn {
    display: absolute;
    height: 85.05px;
    width: 85.05px;
    border-radius: 100%;
    background: linear-gradient(135deg, #2c4a72 0%, #1d3152 100%);
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.18);
    color: white;
    border: 0px;
    outline: none !important;
}

.chat-widget-btn i {
    position: relative;
    font-size: 35px;
}

.chat-widget-btn .notify {
    position: absolute;
    top: -5px;
    right: -5px;
    height: 30px;
    width: 30px;
    background-color: #c22a2a;
    border-radius: 50px;
    color: #ffffff;
    font-family: "Nunito", sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.chat-widget-btn i.fa-comments::after {
    content: "=";
    position: absolute;
    /* width: 12px; */
    /* height: 2.5px; */
    /* background-color: #274167; */
    top: 12px;
    left: 6px;
    font-size: 24px;
    color: #274167;
    display: inline-block;
    line-height: 0;
}
.chat-box-sec {
    font-family: "Nunito", sans-serif;
    max-width: 280px;
    width: 100%;
    border-radius: 3px;
    background-color: #ffffff;
    overflow: hidden;
    box-shadow: 3px 3px 16px 1px rgba(0, 0, 0, 0.18);
    margin-bottom: 20px;
}

.chat-box-header {
    display: flex;
    align-items: center;
    max-width: 100%;
    width: 100%;
    min-height: 77px;
    border-radius: 3px 3px 0 0;
    /* background: linear-gradient(135deg, #2C4A72 0%, #1D3152 100%); */
    padding: 15px;
    box-sizing: border-box;
    justify-content: space-between;
    position: relative;
}

.chat-box-header figure {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    margin: 0px;
}

.chat-box-header figure h2 {
    color: #ffffff;
    font-size: 19px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 0px;
}

.chat-box-header figure p {
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    margin-bottom: 0px;
}

.chat-box-header figure p span.status {
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 13px;
    margin-left: 10px;
}

.chat-box-header .chat-profile {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
}

.chat-box-header .chat-profile img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.ChatCloseshowLeft {
    background: transparent !important;
    border: none;
    color: #fff;
    position: absolute;
    top: -12px;
    cursor: pointer;
    right: 18px;
}
.chatCloseicon li {
    background: none repeat scroll 0 0 white;
    height: 5px;
    width: 5px;
    line-height: 0;
    list-style: none outside none;
    margin-top: 3px;
    vertical-align: top;
    border-radius: 50%;
    pointer-events: none;
}
/* .btn-left {
    left: 0.4em;
  } */
.ChatClosebtn {
    right: 0.4em;
}
/* .btn-left, */
.ChatClosebtn {
    position: absolute;
    top: 0.24em;
}
.chatCloseDropbtn {
    background: transparent !important;
    border: none;
    color: #fff;
    position: absolute;
    top: -12px;
    cursor: pointer;
    right: 18px;
}
.chatCloseDropbtn:hover,
.chatCloseDropbtn:focus {
    background-color: #3e8e41;
}
.chat-close-dropdown {
    position: absolute;
    display: inline-block;
    right: 0.4em;
}
.chat-close-dropdown-content {
    display: none;
    background-color: #f9f9f9;
    min-width: 80px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    z-index: 1;
    position: absolute;
    left: -106px;
    top: 20px;
    font-size: 12px;
}

.chat-close-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    font-weight: 600;
}

.chat-close-dropdown a:hover {
    background-color: #f1f1f1;
}

.showChatClose {
    display: block;
}

.chat-box-body {
    position: relative;
    height: 400px;
    padding: 0px 15px 10px 5px;
    overflow-x: hidden;
    /* overflow-y: scroll; */
    margin: 0px auto;
}

.welcome-msg-sec {
    position: relative;
    width: 100%;
    padding: 5px 5px 5px 15px;
    /* overflow: hidden; */
}

.chat-box-body .chat-bubble {
    position: relative;
    max-width: 70%;
    /* width: 100%; */
    width: auto;
    color: #000000;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    background-color: #f6f7f8;
    box-shadow: 0px 2px 4px 2px #e0e0e0;
    padding: 10px 15px;
    border-radius: 15px;
    /* margin-left: 10px; */
    text-align: left;
    margin-bottom: 5px;
    display: table;
}

.chat-box-body .chat-bubble::after {
    /* content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 13px solid #f6f7f8;
    border-bottom: 7px solid transparent;
    left: -7px;
    bottom: -5px;
    transform: rotate(-46deg); */
}

.chat-box-body .chat-box-input {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: 100%;
}

.chat-box-body .chat-box-input input {
    width: calc(100% - 90px);
    padding: 0px 10px;
    height: 100%;
    outline: none !important;
    resize: none;
    font-size: 14px;
    border: 0px;
}

.chat-box-body .chat-box-input input::-webkit-input-placeholder {
    color: #a7b9c5;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 16px;
}

.chat-box-body .chat-box-input input:-ms-input-placeholder {
    color: #a7b9c5;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 16px;
}

.chat-box-body .chat-box-input input::placeholder {
    color: #a7b9c5;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 16px;
}
.chat-box-input svg {
    font-size: 24px;
}
.chat-box-body .chat-box-input div {
    font-size: 24px;
    width: 30px;
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    color: #a7b9c5;
}

.chat-box-field-body {
    position: relative;
    height: 40px;
    padding: 10px 15px 10px 20px;

    margin: 0px auto;
}

.chat-box-field-body .chat-box-input {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /* height: 40px; */
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-width: 100%;
    width: 100%;
}

.chat-box-field-body .chat-box-input input {
    width: calc(100% - 90px);
    padding: 0px 10px;
    height: 100%;
    outline: none !important;
    resize: none;
    font-size: 14px;
    border: 0px;
}

.chat-box-field-body .chat-box-input input::-webkit-input-placeholder {
    color: #a7b9c5;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 16px;
}

.chat-box-field-body .chat-box-input input:-ms-input-placeholder {
    color: #a7b9c5;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 16px;
}

.chat-box-field-body .chat-box-input input::placeholder {
    color: #a7b9c5;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 16px;
}
.chat-box-field-body .chat-box-input button {
    font-size: 24px;
    width: 30px;
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    color: #a7b9c5;
    border: none;
    background-color: transparent;
}

.chat-box-field-body .chat-box-input textarea {
    width: calc(100% - 90px);
    padding: 0px 10px;
    height: 100%;
    outline: none !important;
    resize: none;
    font-size: 14px;
    border: 0px;
    padding-bottom: 2px;
    font-family: "Nunito", sans-serif;
}

.chat-box-field-body .chat-box-input textarea::-webkit-input-placeholder {
    color: #a7b9c5;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 16px;
}

.chat-box-field-body .chat-box-input textarea:-ms-input-placeholder {
    color: #a7b9c5;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 16px;
}

.chat-box-field-body .chat-box-input textarea::placeholder {
    color: #a7b9c5;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 16px;
}

.chat-box-footer {
    position: relative;
    height: 20px;
    color: #a7b9c5;
    font-family: 'Nunito', sans-serif;
    font-size: 8.5px;
    letter-spacing: 0;
    line-height: 8px;
    text-align: center;
    background-color: #22385b;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.footer-text {
    font-family: Nunito;
    font-weight: 700;
}

.quick-msg {
    position: relative;
    width: 100%;
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;
    max-width: 70%;
    margin-bottom: 5px;
}

.quick-msg span.q-bubble {
    position: relative;
    display: inline-block;
    color: #ffffff;
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 11px;
    border-radius: 50px;
    background-color: #246b47;
    padding: 8px 12px;
    margin: 0px 5px 5px;
}
.chat-widget .row {
    justify-content: flex-end;
    align-items: flex-end;
}
/* Scroll Bar */
.myScroll::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.myScroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.myScroll::-webkit-scrollbar-thumb {
    background: #84868d;
}

/* Handle on hover */
.myScroll::-webkit-scrollbar-thumb:hover {
    background: #84868d;
    cursor: pointer;
}

.myScroll {
    scrollbar-color: #84868d #f1f1f1;
    scrollbar-width: thin;
}

/* Scroll End */
section.chat-widget {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 80%;
}
section.chat-box-sec {
    display: none;
}
.chat-box-footer strong {
    padding: 0 3px;
}
.chat-box-body .chat-box-input img:hover {
    cursor: pointer;
}
/* Other */
.chat-box-input {
    position: relative;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 100%;
    width: 100%;
    padding-right: 35px;
    box-sizing: border-box;
}

.chat-box-input input {
    width: calc(100% - 100px);
    padding: 0px 10px;
    height: 100%;
    outline: none !important;
    resize: none;
    font-size: 14px;
    border: 0px;
}

.chat-box-input div {
    width: 23px;
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    color: #a7b9c5;
    margin-right: 0px;
}
.chat-box-input img:hover {
    cursor: pointer;
}
.fadeOut {
    opacity: 0;
    width: 0;
    height: 0;
    transition: width 0.2s 0.2s, height 0.2s 0.2s, opacity 0.2s;
}
.fadeIn {
    opacity: 1;
    transition: width 0.2s, height 0.2s, opacity 0.5s 0.2s;
}
.resolveChatModalBlur {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
}
.chat-widget .chat-widget-btn svg {
    font-size: 35px;
}

.unseen-message-count-icon-wrapper {
    position: absolute;
    top: 0;
    right: -4px;
    background: rgb(158, 1, 1);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-device-width: 767px) {
    .chat-box-sec {
        max-width: 400px;
        width: 400px;
    }
    .email-modal {
        width: auto;
    }
}

@media (min-device-width: 490px) {
    section.chat-widget {
        width: auto;
    }
}

.vertical-align-middle {
    vertical-align: middle;
}

.status-indicator {
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    z-index: 1;
}
.logo-container {
    display: flex;
    place-content: end;
}

.status-online {
    background-color: #3aaf50;
}
.status-online-text {
    color: #3aaf50;
}
.status-offline {
    background-color:#c22a2a;
}
.status-offline-text {
    color:#c22a2a;
}
.widget-icon {
    width: 35px;
    height: 30px;
}
.margin-right {
    margin-right: 15px !important;
}

.margin-top {
    margin-top: 6px !important;
}
.custom-btn .email-btn.send:disabled {
    border-radius: 10px;
    background-color: #84868d !important;
    border-width: 0 !important;  
}
.field-container {
    display: flex;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #c6cdd3;
    background-color: #fff;
    padding-left: 8px;
    transition: 0.3s;
}
.field-selected {
    transition: 0.3s;
    border-color: #2d4c62 !important;
}
.font-15px {
    font-size: 15px;
}
.lda-text-gray {
    transition: 0.3s;
    color: rgb(138, 138, 138);
}
.att-btn input {
    display: none;
}
.att-btn label {
    border-radius: 3px;
    background-color: #1d3152;
    padding: 10px 33px;
    color: #fff;
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
}
.att-btn label:hover {
    background-color: #246b47;
    border-color: #246b47;
}

.as-complete-modal h3 {
    color: #1d3152;
    font-family: "Nunito", sans-serif;
    font-size: 19px;
    font-weight: 800;
}
.as-complete-modal p {
    color: #000;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
    padding-bottom: 5px;
}
.as-complete-modal .modal-header {
    border: 0;
    padding: 0px;
}
.as-complete-modal .modal-footer {
    border: 0;
    justify-content: center;
    padding: 0px;
}
.as-complete-modal button {
    border: 1px solid transparent;
    border-radius: 3px;
    background-color: #1d3152;
    color: #fff;
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    transition: all ease-in-out 0.3s;
    height: auto;
    padding: 0 5px;
}
/* .as-complete-modal button:hover {
    background-color: #246b47;
    border-color: #246b47;
    color: #fff;
} */
.as-complete-modal button.btn-primary:disabled {
    border-radius: 3px;
    background-color: #a7b9c5;
    padding: 6px 21px;
    color: #fff;
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    border-color: #a7b9c5;
    opacity: 1;
}
.as-complete-modal button.btn-close {
    padding: 0;
    background-color: transparent;
    color: #748997;
    opacity: 1;
    margin: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: all ease-in-out 0.3s;
    -webkit-filter: invert(62%) sepia(26%) saturate(280%) hue-rotate(161deg) brightness(81%) contrast(85%);
            filter: invert(62%) sepia(26%) saturate(280%) hue-rotate(161deg) brightness(81%) contrast(85%);
}
.as-complete-modal button.btn-close:hover {
    -webkit-filter: invert(13%) sepia(72%) saturate(806%) hue-rotate(185deg) brightness(91%) contrast(89%);
            filter: invert(13%) sepia(72%) saturate(806%) hue-rotate(185deg) brightness(91%) contrast(89%);
}
.as-complete-modal .modal-content {
    border: 1px solid #dee4e7;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 6px 6px 16px 1px rgba(0, 0, 0, 0.18);
    /* height: 229px; */
    padding: 15px;
    text-align: center;
}
.as-complete-modal .modal-dialog.modal-dialog-centered {
    max-width: 150px;
    position: relative;
    z-index: 9999999999;
    right: 0;
    margin: auto;
    top: 186px;
    left: 10px;
}
.as-complete-modal-mobile .modal-dialog-centered{
    left: -15px !important;
}
.as-complete-modal .modal-body {
    padding: 0px;
}
.auto-comp-modal .modal-content {
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.18), 0 -2px 6px 4px rgba(0, 0, 0, 0.05);
    height: 170px;
    border: 0;
    border-top: 12.39px solid #246b47;
    padding: 25px 30px;
}
.auto-comp-modal .modal-dialog.modal-dialog-centered {
    max-width: 464px;
}
.auto-comp-modal .checkicon-sec {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.auto-comp-modal .checkicon-sec span {
    border: 3px solid #c6d0d5;
    height: 20.82px;
    width: 20.81px;
    display: block;
    border-radius: 50%;
}
.auto-comp-modal .checkicon-sec svg {
    font-size: 36px;
    color: #246b47;
    position: absolute;
    top: -9px;
    line-height: normal;
    height: auto;
    padding-left: 4px;
}
.auto-comp-modal p {
    color: #344c60;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}
.auto-comp-modal button {
    color: #638ccf;
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-weight: 500;
    background: transparent;
    padding: 0;
    border: 0;
    text-decoration: underline;
}
.auto-comp-modal button:hover {
    background: transparent;
    color: #246b47;
}
.auto-comp-modal button:focus {
    color: #638ccf;
    background: transparent;
    outline: none !important;
    box-shadow: none;
}
.auto-comp-modal .modal-footer {
    margin-top: 6px;
}
.greenBtn {
    background-color: #246b47 !important;
    border-color: #246b47 !important;
}

/*====== 
Fonts 
======*/
/*================== 
Generic
====================*/
.rounded-circle {
    border-radius: 50%;
}

.round-img-size {
    width: 4.2rem;
    height: 4.2rem;
    object-fit: cover;
}
/*===================
 App View 
 ====================*/

#chat-app {
    height: 100%;
}

#chat-app .chatbox-container {
    position: absolute;
    top: 2rem;
    right: 2rem;
    bottom: 4rem;
    left: 2rem;
    display: block;
    float: right;
}

#chat-app .chatbox-container-integrated {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

#chat-app .chatbox-container .chatbox-wrapper-smooth {
    z-index: 1;
    width: 100%;
    height: 100%;
    box-shadow: 0 1.6rem 1.8rem rgba(0, 0, 0, 0.2) !important;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    background: rgba(255, 255, 255, 1);
}

#chat-app .chatbox-container .chatbox-wrapper-smooth-integrated {
    border-radius: 0;
}
#chat-app .chatbox-wrapper-smooth .chatbox-content-wrapper {
    width: 100%;
    height: 100%;
}

#chat-app .chatbox-content-wrapper .chat-header-wrapper-smooth {
    z-index: 6;
    position: relative;
    width: 100%;
    overflow: hidden;
    float: left;
}

#chat-app .chatbox-content-wrapper .chat-header-smooth {
    height: 6rem;
    z-index: 6;
    width: 100%;
    padding: 0 3.5rem 0 1.5rem;
}

#chat-app .chatbox-content-wrapper .chat-header-smooth.widget-intergrate {
    padding: 0 1.5rem 0 3.5rem;
}

#chat-app .chatbox-content-wrapper .chat-header-smooth .company-name-wrapper {
    float: right;
    height: 100%;
    margin: 0;
    overflow: hidden !important;
    position: relative;
    text-overflow: ellipsis;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 85%;
    white-space: nowrap;
}

#chat-app .chatbox-content-wrapper .chat-header-smooth .company-name-wrapper-integrated {
    text-align: center;
    width: 80%;
}

#chat-app .chat-header-smooth .company-name-wrapper .company-name {
    cursor: pointer;
    display: inline-block;
    margin-top: 2.1rem;
    font-size: 1.6rem;
    font-weight: 600;
}

#chat-app .chat-header-smooth .dotted-overflow-wrapper {
    margin-top: 2.3rem;
    white-space: nowrap;
    overflow: hidden !important;
    width: 15%;
    float: left;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

#chat-app .chat-header-smooth .right-dotted-overflow-wrapper {
    float: right;
    text-align: right;
    width: 20%;
}

#chat-app .chat-header-smooth .dotted-overflow {
    cursor: pointer;
    display: inline-block;
    position: relative;
}

#chat-app .chat-header-smooth .dotted-overflow .dot {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-right: 1px;
    background: #ffffff;
}

#chat-app .chat-header-wrapper-smooth .agent-bar-wrapper {
    z-index: 6;
    position: relative;
    width: 100%;
    overflow: hidden;
    float: left;
}
#chat-app .chat-header-wrapper-smooth .agent-bar-wrapper .agent-bar {
    opacity: 0.95;
    height: 6rem;
    z-index: 6;
    width: 100%;
    padding: 0 3.5rem 0 10.5rem;
}

#chat-app .chat-header-wrapper-smooth .agent-bar .agent-profile-picture-wrapper {
    position: absolute;
    width: 7rem;
    height: 100%;
    left: 3.5rem;
    top: 0;
    /* margin-top: 8px; */
}

#chat-app .chat-header-wrapper-smooth .agent-bar .agent-profile-picture {
    width: 5rem;
    height: 5rem;
    display: block;
    position: relative;
}

#chat-app .chat-header-wrapper-smooth .agent-bar .avatar-wrapper {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 100%;
    overflow: hidden;
}

#chat-app .chat-header-wrapper-smooth .agent-bar .avatar {
    height: 100%;
}

#chat-app .chat-header-wrapper-smooth .agent-bar .avatar-wrapper img {
    display: block;
    background: transparent !important;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
#chat-app .chat-header-wrapper-smooth .agent-bar .agent-details {
    position: relative;
}
#chat-app .chat-header-wrapper-smooth .agent-bar .agent-name {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-transform: capitalize;
    margin-top: 0.5rem;
    width: 100%;
    float: left;
    font-size: 1.8rem;
    font-weight: 500;
}

#chat-app .chat-header-wrapper-smooth .agent-bar .agent-title {
    font-size: 1.4rem;
    line-height: 1.5rem;
    width: 100%;
    opacity: 0.7;
    float: left;
    font-weight: 300;
}

#chat-app .overflow-menu {
    top: 3rem;
    position: absolute;
    height: 4.2rem;
    box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 1.5rem,
        rgba(101, 119, 134, 0.15) 0px 0px 0.3rem 0.1rem;
    border-radius: 0.4rem;
    background: white;
    z-index: 99;
    display: none;
}

#chat-app .right-overflow-menu {
    top: 4rem;
    right: 3rem;
}

#chat-app .left-overflow-menu {
    left: 3rem;
}

#chat-app .overflow-menu.show {
    display: inline;
    display: initial;
}

#chat-app .overflow-menu .overflow-content {
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-size: 1.4rem;
    font-weight: 400;
    font-family: "Nunito", sans-serif;
    letter-spacing: 0.6px;
    padding: 1em;
    color: rgb(100, 100, 100);
}

#chat-app .overflow-menu .overflow-content.disabled {
    opacity: 0.5;
    pointer-events: none;
}

#chat-app .chat-header-smooth .chat-close {
    cursor: pointer;
    z-index: 8;
    position: absolute;
    display: block;
    width: 2rem;
    height: 2rem;
    right: 1.3rem;
    top: 2.3rem;
    padding: 0.3rem;
    border-radius: 100%;
}

#chat-app .chat-header-smooth .chat-back-icon {
    cursor: pointer;
    z-index: 8;
    position: absolute;
    display: block;
    width: 2rem;
    height: 2rem;
    left: 1.3rem;
    top: 2.3rem;
    padding: 0.3rem;
}

#chat-app .chat-content {
    float: left;
    position: relative;
    width: 100%;
}

#chat-app .chat-content .conversation {
    float: left;
    position: relative;
    width: 100%;
    height: calc(100% - 6rem);
    min-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 2rem 0 1rem 0 !important;
}

#chat-app .chat-content .conversation .conversation-wrapper {
    width: 100%;
}

#chat-app .conversation .message-loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-style: italic;
    text-transform: capitalize;
    color: #000000ba;
}

#chat-app .conversation .conversation-end-btn {
    position: fixed;
    bottom: 7.7rem;
    right: 0.7rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 0.3rem 0.5rem -0.1rem rgba(0, 0, 0, 0.06),
        -0.1rem 0.6rem 1rem 0px rgba(0, 0, 0, 0.06), 0px 0.1rem 1.8rem 0px rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#chat-app .conversation .conversation-end-btn .text {
    background: orange;
    position: absolute;
    color: #fff;
    font-size: 0.8rem;
    padding: 0.2rem;
    min-width: 1.5rem;
    border-radius: 50%;
    min-height: 1.5rem;
    text-align: center;
    right: -3px;
    top: -2px;
}

#chat-app .conversation .conversation-end-btn:hover {
    cursor: pointer;
}

#chat-app .conversation .conversation-end-btn .text {
    color: #fff;
}

/* #chat-app .conversation .image-preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 6rem;
    min-height: 10rem;
    background: aliceblue;
    width: 100%;
} */

#chat-app .renderUploadFile .image-preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 0px;
    width: 100%;
    max-width: 120px;
    overflow: hidden;
    background: aliceblue;
}

#chat-app .renderUploadFile .image-preview-container.file {
    background: transparent;
    width: auto;
}

#chat-app .renderUploadFile .image-preview-container .file-preview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #eeeeee;
    border-radius: 1.6rem;
    padding: 1.8rem 1.4rem;
    position: relative;
    min-width: 9.5rem;
    min-height: 9rem;
}

.image-preview-container .file-preview .file-name {
    margin-top: 0.5rem;
    max-width: 6.4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* #chat-app .conversation .image-preview-container .image-preview {
    max-width: 12.5rem;
    max-height: 9rem;
    object-fit: contain;
} */

#chat-app .renderUploadFile .image-preview-container .image-preview {
    max-width: 100%;
    object-fit: contain;
    max-height: 150px;
}

#chat-app .renderUploadFile .image-preview-container .image-preview.file {
    max-width: 5.5rem;
    max-height: 3.3rem;
    object-fit: contain;
}

/* #chat-app .conversation .image-preview-container .cross-icon {
    position: absolute;
    right: 1.2rem;
    top: 1rem;
    filter: brightness(0.5);
    cursor: pointer;
} */

#chat-app .renderUploadFile .image-preview-container .cross-icon {
    position: absolute;
    right: 0.2rem;
    top: 0.4rem;
    -webkit-filter: brightness(0.5);
    filter: brightness(1);
    cursor: pointer;
    padding: 3px;
    width: 12px;
    background: #000;
}

#chat-app .renderUploadFile .image-preview-container .cross-icon.file {
    right: 0.6rem;
    top: 0.8rem;
}

#chat-app .conversation .message-loading-top {
    width: 100%;
    position: absolute;
    top: 0.4rem;
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    font-style: italic;
    text-transform: capitalize;
    color: #000000ba;
    z-index: 999;
}

#chat-app .chat-content .conversation .conversation-wrapper .reply {
    width: 100%;
    /* float: left; */
    clear: both;
    margin-bottom: 0rem;
    padding: 0 2rem;
    position: relative;
}

#chat-app .chat-content .conversation .conversation-wrapper .reply .reply-date {
    clear: both;
    float: left;
    font-size: 1.1rem;
    margin-bottom: 0.6rem;
    margin-top: 0.6rem;
    text-align: center;
    width: 100%;
}

#chat-app .chat-content .conversation .conversation-wrapper .reply .reply-note {
    clear: both;
    float: left;
    font-style: italic;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
}

#chat-app .chat-content .conversation .conversation-wrapper .reply .reply-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 5px 5px 5px 15px;
}

#chat-app
    .chat-content
    .conversation
    .conversation-wrapper
    .reply
    .reply-wrapper
    .reply-content.admin,
#chat-app
    .chat-content
    .conversation
    .conversation-wrapper
    .reply
    .reply-wrapper
    .reply-content.user {
    width: 70%;
    min-width: 23rem;
    max-width: 50rem;
}

#chat-app
    .chat-content
    .conversation
    .conversation-wrapper
    .reply
    .reply-wrapper
    .reply-content.user
    .chat-message {
    max-width: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
    border: 1px solid transparent;
    display: block;
    padding: 0.7rem 1.4rem;
    border-radius: 1.9rem;
    float: right;
    text-align: left;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.9rem;
}

#chat-app
    .chat-content
    .conversation
    .conversation-wrapper
    .reply
    .reply-wrapper
    .reply-content.admin
    .chat-message.image,
#chat-app
    .chat-content
    .conversation
    .conversation-wrapper
    .reply
    .reply-wrapper
    .reply-content.user
    .chat-message.image {
    line-height: 1.1rem;
    padding: 0.7rem;
}

.imgMessageWithChat {
    padding: 0 !important;
    border-radius: 0 !important;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    /* margin-bottom: 1.2rem; */
}

.imgMessage {
    padding: 0.5rem !important;
    height: 80px;
    width: 80px;
}

.chat-message .chat-message-image {
    width: 80px;
    height: 80px;
    width: auto;
    max-width: 200px;
}

.chat-message .chat-message-image-content {
    width: 80px;
    height: 80px;
    width: auto;
    max-width: 200px;
}

.chat-message .chat-message-document {
    /* width: 100%;
    height: 100%; */
    width: 50px;
    height: 50px;
}

.chat-message .chat-message-document-content {
    width: 50px;
    height: 50px;
}

.chat-message .chat-message-attachment {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-message .chat-message-attachment img {
    max-height: 1.5rem;
    margin-right: 0.5rem;
}

.chat-message .chat-message-attachment a {
    color: #fff;
    word-break: break-all;
}

#chat-app
    .chat-content
    .conversation
    .conversation-wrapper
    .reply
    .reply-wrapper
    .reply-content.admin
    .chat-message {
    min-height: 3.5rem;
    max-width: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
    border: 1px solid transparent;
    display: block;
    padding: 0.7rem 1.4rem;
    border-radius: 1.9rem;
    float: left;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.9rem;
}

.chat-message .message-caption {
    padding-top: 8px;
    padding-left: 5px;
    line-height: 1.2;
}
#chat-app
    .chat-content
    .conversation
    .conversation-wrapper
    .reply
    .reply-wrapper
    .reply-content.user {
    float: right;
}

#chat-app
    .chat-content
    .conversation
    .conversation-wrapper
    .reply
    .reply-wrapper
    .reply-content.admin {
    float: left;
}

#chat-app .chat-content .conversation .conversation-wrapper .reply .reply-wrapper .user-name {
    margin-left: 1rem;
    padding: 0.5rem 0;
    font-size: 1rem;
    letter-spacing: 0.2px;
    text-transform: capitalize;
}
#chat-app .chat-content .conversation .conversation-wrapper .reply .reply-wrapper .reply-content {
    min-height: 4rem;
}

#chat-app .chat-content .chat-field {
    z-index: 3;
    position: relative;
    height: 6rem;
    width: 100%;
    float: left;
    background: rgb(255, 255, 255);
    opacity: 0.6;
}

.chat-field form {
    display: inline;
}

.chat-field textarea {
    color: rgb(0, 0, 0);
    font-family: inherit;
    font-size: 1.4em;
    font-weight: 300;
    padding: 2rem 0 0.4rem 2.5rem;
    background: transparent;
    width: calc(100% - 70px);
    height: 100%;
    border: 0;
    float: left;
    resize: none;
}

.chat-field textarea::-webkit-input-placeholder {
    color: inherit;
    font-size: inherit;
}

.chat-field textarea:-ms-input-placeholder {
    color: inherit;
    font-size: inherit;
}

.chat-field textarea::placeholder {
    color: inherit;
    font-size: inherit;
}

#chat-app .chat-content .chat-field .actions {
    display: flex;
    justify-content: space-between;
    float: right;
    height: 100%;
    padding: 2.2rem 2rem 2.2px 0px;
    width: 7rem;
    overflow: hidden;
}

#chat-app .chat-content .chat-field .actions .action-btn {
    cursor: pointer;
    position: relative;
    display: block;
    background: transparent;
    width: 2rem;
    border: none;
    height: 2rem;
}

#chat-app .chat-content .chat-field .actions .action-btn.send {
    opacity: 0.5;
}

#chat-app .chat-content .chat-field .actions .action-btn.send.allowed {
    opacity: 1;
}

#chat-app .chat-content .chat-field .actions .action-btn.add span {
    position: absolute;
}

#chat-app .chat-content .chat-field .actions .action-btn.add:hover {
    cursor: pointer;
}

#chat-app .action-btn.add input[type="file"]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
}

#chat-app .chat-content .chat-field .actions .action-btn.add span input {
    width: 2rem;
    height: 2rem;
    opacity: 0;
}
.agent-bottom-message {
    display: flex;
    justify-content: space-between;
    text-align: center;
    position: absolute;
    bottom: 1.2rem;
    margin: 0 1.5rem;
    width: calc(100% - 3rem);
}

.agent-bottom-message p {
    align-self: center;
    color: rgba(0, 0, 0, 0.7);
    font-size: 1.5rem;
}

.agent-bottom-message {
    display: flex;
    justify-content: space-between;
    text-align: center;
    position: absolute;
    bottom: 1.2rem;
    margin: 0 1.5rem;
    width: calc(100% - 3rem);
}

.agent-bottom-message p {
    align-self: center;
    color: rgba(0, 0, 0, 0.7);
    font-size: 1.5rem;
}

#chat-app .chat-button-wrapper {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
}
#chat-app .chat-button-wrapper.bar {
    width: 28rem;
    height: 5.5rem;
}

#chat-app .chat-button {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 100%;
    cursor: pointer;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
    background: #f39c12;
    color: rgb(255, 255, 255);
}

#chat-app .chat-button-wrapper.bar .chat-button {
    border-radius: 1rem;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
}

#chat-app .chat-button-wrapper .chat-button span {
    display: none;
}

#chat-app .chat-button-wrapper.bar .chat-button span {
    display: inline-block;
    font-size: 1.8rem;
}

#chat-app .chat-button img {
    margin: 12px 0 0 13px;
}

#chat-app .chat-button-wrapper.bar .chat-button img {
    margin: 0;
}

.email-modal-backrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(247, 247, 247, 0.8);
    z-index: 9;
}

.email-modal-backrop.fixed {
    position: fixed;
}

.email-modal-backrop.hide {
    display: none;
}

.email-modal-backrop.email-form {
    background: transparent;
}

.email-modal {
    box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 1.5rem,
        rgba(101, 119, 134, 0.15) 0px 0px 0.3rem 0.1rem;
    background: #f6f7f8;
    left: 8px;
    border-radius: 1.5rem;
    z-index: 99;
    width: 90%;
}

.email-modal.email-form {
    min-height: 100%;
    background: rgba(255, 255, 255, 1);
}

.email-modal .email-modal-content-wrapper {
    position: relative;
    /* width: 25rem; */
}

.email-modal .email-modal-content {
    width: 100%;
    padding: 0 1rem 1.2rem 1rem;;
}

.email-modal .email-modal-content-heading {
    background-color: #22385b;
    padding: 1rem 0 1rem 1.5rem;
    margin-bottom: 1em;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
}

.email-modal .email-modal-content-heading h1 {
    color: #ffffff;
    display: flex !important;
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    font-size: 1.4em;
    text-align: left;
    /* margin: 1.5rem 0 1.5rem 0; */
    letter-spacing: 0.6px;
}

.email-modal .email-modal-content .email-modal-input {
    margin-bottom: 0.5rem;
}

.email-modal .email-modal-content .email-modal-input .error {
    border: 1px solid red;
}

.email-modal .email-modal-content .email-modal-input input,
.email-modal .email-modal-content .email-modal-input textarea {
    z-index: 1;
    display: block;
    overflow: hidden;
    box-sizing: border-box;
    margin: 0;
    padding: 0 11px;
    width: 100%;
    height: 3rem;
    outline: 0;
    border: 1px solid #c6cdd3;
    border-radius: 2px;
    background-color: #fff;
    color: #000000;
    vertical-align: middle;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Nunito", sans-serif;
    font-size: 1.5em;
    font-weight: 400;
    transition: border 0.3s ease, background-color 0.3s ease, color 0.3s ease, padding 0.3s ease;
}

.email-modal .email-modal-content .email-modal-input textarea {
    height: auto;
    padding: 0.8px 1.1rem;
}

.email-modal .email-modal-content .email-modal-input input:focus,
.email-modal .email-modal-content .email-modal-input textarea:focus {
    /* Dynamic */
    /* border: 1px solid #22385b; */
    border: 1px solid #2d4c62;
}

.email-modal-btn-wrapper {
    display: flex;
    display: -ms-flexbox;
    justify-content: center;
    /* margin: 0.8px 0 1.5rem 0; */
}

.email-modal-btn-wrapper .email-btn {
    width: auto;
    border-radius: 0.2rem;
    letter-spacing: 0.6px;
    /* text-transform: uppercase; */
    overflow: hidden;
    margin: 0 0.4rem;
    max-width: 100%;
}

.email-modal-btn-wrapper .email-btn.send {
    background: #f39c12;
    border: 1px solid #f39c12;
    padding: 0 1.5rem;
    height: 2.5em;
    line-height: 0;
}
.email-modal-btn-wrapper .email-btn.send:disabled {
    opacity: 0.5;
}

.email-modal-btn-wrapper .email-btn.cancel {
    background: lightgray;
    border: 1px solid lightgray;
}

.agent-chat-closed {
    font-size: 15px;
    text-align: center;
    color: #84868d;
}

.btn {
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 1.3em;
    font-family: "Nunito", sans-serif;
    padding: 0 3.6rem;
    height: 3em;
    line-height: 3em;
    transition: background-color 0.3s, color 0.3s;
    box-sizing: border-box;
    -webkit-appearance: none;
    outline: 0;
}

.btn-chat-start {
    background: rgb(243, 156, 18);
    border: 1px solid rgb(243, 156, 18);
    border-radius: 0.5rem;
    padding: 0 1.5rem;
    height: 2.8em;
    line-height: 2.8em;
}

.typing-animation {
    position: relative;
}

.typing-animation .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 3px;
    background: #748495;
    -webkit-animation: typingAnimation 1.3s linear infinite;
            animation: typingAnimation 1.3s linear infinite;
}

.typing-animation .dot:nth-child(2) {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
}

.typing-animation .dot:nth-child(3) {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
}

.reply .read-bubble-container {
    top: 100%;
    position: absolute;
    right: 0px;
    display: flex;
}

.read-bubble-container .read-bubble {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    margin-right: 6px;
}

.read-bubble-container .read-bubble img {
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.notify-dot {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
    max-width: 30px;
    max-height: 20px;
    width: 10px;
    height: 10px;
    font-weight: 600;
    line-height: 16px;
    color: #fff;
    white-space: nowrap;
    vertical-align: baseline;
    font-size: 1.3rem;
    border-radius: 50%;
    border: 1.5px solid;
    border-color: #fff;
    position: absolute;
    right: 75%;
    top: -1px;
}

.chat-button-wrapper.bar .notify-dot {
    right: 95%;
    top: -9px;
}

@-webkit-keyframes typingAnimation {
    0%,
    60%,
    100% {
        -webkit-transform: none;
                transform: none;
        -webkit-transform: initial;
                transform: initial;
    }
    30% {
        -webkit-transform: translateY(-8px);
                transform: translateY(-8px);
    }
}

@keyframes typingAnimation {
    0%,
    60%,
    100% {
        -webkit-transform: none;
                transform: none;
        -webkit-transform: initial;
                transform: initial;
    }
    30% {
        -webkit-transform: translateY(-8px);
                transform: translateY(-8px);
    }
}

@media only screen and (max-width: 280px) {
    html {
        font-size: 8px;
    }
    #swift-sales-widget {
        width: 35rem;
        height: 56rem;
    }
    /* #chat-app .chat-content .conversation {
        min-width: 248px;
    } */
    .typing-animation .dot {
        width: 4px;
        height: 4px;
    }
    #chat-app .chat-content .chat-field .actions .action-btn svg {
        width: 14px;
        height: 14px;
    }
    #chat-app .chat-button-wrapper.bar .chat-button img {
        width: 30px;
    }
}

@media only screen and (max-width: 90px) {
    #chat-app .chat-button-wrapper {
        bottom: 16px;
        right: 16px;
        width: 48px;
        height: 48px;
    }

    #chat-app .chat-button img {
        width: 30px;
        margin: 9.6px 0 0 10.4px;
    }
}

@media (min-device-width: 490px) {
    #chat-app .chat-content .conversation {
        min-width: 32rem;
    }
}
.width-100 {
    width: 100%
}
.typing-indicator {
    position: -webkit-sticky !important;
    position: sticky !important;
    bottom: 0;
}
.form-container {
    position: absolute;
    z-index: 1;
    width: inherit;
    padding: 10px 0 15px 15px;
}
.padding-0 {
    padding: 0 !important;
}
.bottom-0 {
    bottom: 0;
}
.custom-input-field input {
    border-width: 0 !important;
    padding: 0 11px 0 2px !important;
}


#chat-app .chat-content .conversation {
    height: 364px;
}

@media (max-device-width: 575px) {
    #chat-app .chat-content .conversation {
        height: 260px;
    }
}

.typing-hint {
    color: #3aaf50;
    font-size: 12px;
    padding-left: 15px;
    padding-bottom: 8px;
    min-height: 25px;
}

.circle-xxxl {
    width: 40px;
    height: 40px;
}
.circle-xxl {
    width: 30px;
    height: 30px;
}
.circle-xl {
    width: 25px;
    height: 25px;
}
.circle-lg {
    width: 20px;
    height: 20px;
}
.circle-md {
    width: 15px;
    height: 15px;
}
.circle-sm {
    width: 11px;
    height: 11px;
}
.sk-fading-circle.green .sk-circle:before {
    background-color: #3aaf50;
}
.sk-fading-circle.gray .sk-circle:before {
    background-color: #444444;
   
}
.sk-fading-circle.orange .sk-circle:before {
    background-color: #f4a11f;
}
button .sk-fading-circle {
    margin-left: 5px;
}
.sk-fading-circle {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 8px;
   
}
.sk-fading-circle-image-loader {
   
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    padding: 20px;
}

.sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-fading-circle .sk-circle:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: white;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
}

@keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
}

