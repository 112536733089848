main {
    position: relative;
    display: block;
    width: 100%;
    height: 100vh;
}

.chat-widget .chat-widget-btn {
    position: relative;
    float: right;
}

/* Current */
.chat-widget-btn {
    display: absolute;
    height: 85.05px;
    width: 85.05px;
    border-radius: 100%;
    background: linear-gradient(135deg, #2c4a72 0%, #1d3152 100%);
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.18);
    color: white;
    border: 0px;
    outline: none !important;
}

.chat-widget-btn i {
    position: relative;
    font-size: 35px;
}

.chat-widget-btn .notify {
    position: absolute;
    top: -5px;
    right: -5px;
    height: 30px;
    width: 30px;
    background-color: #c22a2a;
    border-radius: 50px;
    color: #ffffff;
    font-family: "Nunito", sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.chat-widget-btn i.fa-comments::after {
    content: "=";
    position: absolute;
    /* width: 12px; */
    /* height: 2.5px; */
    /* background-color: #274167; */
    top: 12px;
    left: 6px;
    font-size: 24px;
    color: #274167;
    display: inline-block;
    line-height: 0;
}
.chat-box-sec {
    font-family: "Nunito", sans-serif;
    max-width: 280px;
    width: 100%;
    border-radius: 3px;
    background-color: #ffffff;
    overflow: hidden;
    box-shadow: 3px 3px 16px 1px rgba(0, 0, 0, 0.18);
    margin-bottom: 20px;
}

.chat-box-header {
    display: flex;
    align-items: center;
    max-width: 100%;
    width: 100%;
    min-height: 77px;
    border-radius: 3px 3px 0 0;
    /* background: linear-gradient(135deg, #2C4A72 0%, #1D3152 100%); */
    padding: 15px;
    box-sizing: border-box;
    justify-content: space-between;
    position: relative;
}

.chat-box-header figure {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 0px;
}

.chat-box-header figure h2 {
    color: #ffffff;
    font-size: 19px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 0px;
}

.chat-box-header figure p {
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    margin-bottom: 0px;
}

.chat-box-header figure p span.status {
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 13px;
    margin-left: 10px;
}

.chat-box-header .chat-profile {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
}

.chat-box-header .chat-profile img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.ChatCloseshowLeft {
    background: transparent !important;
    border: none;
    color: #fff;
    position: absolute;
    top: -12px;
    cursor: pointer;
    right: 18px;
}
.chatCloseicon li {
    background: none repeat scroll 0 0 white;
    height: 5px;
    width: 5px;
    line-height: 0;
    list-style: none outside none;
    margin-top: 3px;
    vertical-align: top;
    border-radius: 50%;
    pointer-events: none;
}
/* .btn-left {
    left: 0.4em;
  } */
.ChatClosebtn {
    right: 0.4em;
}
/* .btn-left, */
.ChatClosebtn {
    position: absolute;
    top: 0.24em;
}
.chatCloseDropbtn {
    background: transparent !important;
    border: none;
    color: #fff;
    position: absolute;
    top: -12px;
    cursor: pointer;
    right: 18px;
}
.chatCloseDropbtn:hover,
.chatCloseDropbtn:focus {
    background-color: #3e8e41;
}
.chat-close-dropdown {
    position: absolute;
    display: inline-block;
    right: 0.4em;
}
.chat-close-dropdown-content {
    display: none;
    background-color: #f9f9f9;
    min-width: 80px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    z-index: 1;
    position: absolute;
    left: -106px;
    top: 20px;
    font-size: 12px;
}

.chat-close-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    font-weight: 600;
}

.chat-close-dropdown a:hover {
    background-color: #f1f1f1;
}

.showChatClose {
    display: block;
}

.chat-box-body {
    position: relative;
    height: 400px;
    padding: 0px 15px 10px 5px;
    overflow-x: hidden;
    /* overflow-y: scroll; */
    margin: 0px auto;
}

.welcome-msg-sec {
    position: relative;
    width: 100%;
    padding: 5px 5px 5px 15px;
    /* overflow: hidden; */
}

.chat-box-body .chat-bubble {
    position: relative;
    max-width: 70%;
    /* width: 100%; */
    width: auto;
    color: #000000;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    background-color: #f6f7f8;
    box-shadow: 0px 2px 4px 2px #e0e0e0;
    padding: 10px 15px;
    border-radius: 15px;
    /* margin-left: 10px; */
    text-align: left;
    margin-bottom: 5px;
    display: table;
}

.chat-box-body .chat-bubble::after {
    /* content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 13px solid #f6f7f8;
    border-bottom: 7px solid transparent;
    left: -7px;
    bottom: -5px;
    transform: rotate(-46deg); */
}

.chat-box-body .chat-box-input {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: 100%;
}

.chat-box-body .chat-box-input input {
    width: calc(100% - 90px);
    padding: 0px 10px;
    height: 100%;
    outline: none !important;
    resize: none;
    font-size: 14px;
    border: 0px;
}

.chat-box-body .chat-box-input input::placeholder {
    color: #a7b9c5;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 16px;
}
.chat-box-input svg {
    font-size: 24px;
}
.chat-box-body .chat-box-input div {
    font-size: 24px;
    width: 30px;
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    color: #a7b9c5;
}

.chat-box-field-body {
    position: relative;
    height: 40px;
    padding: 10px 15px 10px 20px;

    margin: 0px auto;
}

.chat-box-field-body .chat-box-input {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /* height: 40px; */
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-width: 100%;
    width: 100%;
}

.chat-box-field-body .chat-box-input input {
    width: calc(100% - 90px);
    padding: 0px 10px;
    height: 100%;
    outline: none !important;
    resize: none;
    font-size: 14px;
    border: 0px;
}

.chat-box-field-body .chat-box-input input::placeholder {
    color: #a7b9c5;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 16px;
}
.chat-box-field-body .chat-box-input button {
    font-size: 24px;
    width: 30px;
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    color: #a7b9c5;
    border: none;
    background-color: transparent;
}

.chat-box-field-body .chat-box-input textarea {
    width: calc(100% - 90px);
    padding: 0px 10px;
    height: 100%;
    outline: none !important;
    resize: none;
    font-size: 14px;
    border: 0px;
    padding-bottom: 2px;
    font-family: "Nunito", sans-serif;
}

.chat-box-field-body .chat-box-input textarea::placeholder {
    color: #a7b9c5;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 16px;
}

.chat-box-footer {
    position: relative;
    height: 20px;
    color: #a7b9c5;
    font-family: 'Nunito', sans-serif;
    font-size: 8.5px;
    letter-spacing: 0;
    line-height: 8px;
    text-align: center;
    background-color: #22385b;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.footer-text {
    font-family: Nunito;
    font-weight: 700;
}

.quick-msg {
    position: relative;
    width: 100%;
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;
    max-width: 70%;
    margin-bottom: 5px;
}

.quick-msg span.q-bubble {
    position: relative;
    display: inline-block;
    color: #ffffff;
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 11px;
    border-radius: 50px;
    background-color: #246b47;
    padding: 8px 12px;
    margin: 0px 5px 5px;
}
.chat-widget .row {
    justify-content: flex-end;
    align-items: flex-end;
}
/* Scroll Bar */
.myScroll::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.myScroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.myScroll::-webkit-scrollbar-thumb {
    background: #84868d;
}

/* Handle on hover */
.myScroll::-webkit-scrollbar-thumb:hover {
    background: #84868d;
    cursor: pointer;
}

.myScroll {
    scrollbar-color: #84868d #f1f1f1;
    scrollbar-width: thin;
}

/* Scroll End */
section.chat-widget {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 80%;
}
section.chat-box-sec {
    display: none;
}
.chat-box-footer strong {
    padding: 0 3px;
}
.chat-box-body .chat-box-input img:hover {
    cursor: pointer;
}
/* Other */
.chat-box-input {
    position: relative;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 100%;
    width: 100%;
    padding-right: 35px;
    box-sizing: border-box;
}

.chat-box-input input {
    width: calc(100% - 100px);
    padding: 0px 10px;
    height: 100%;
    outline: none !important;
    resize: none;
    font-size: 14px;
    border: 0px;
}

.chat-box-input div {
    width: 23px;
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    color: #a7b9c5;
    margin-right: 0px;
}
.chat-box-input img:hover {
    cursor: pointer;
}
.fadeOut {
    opacity: 0;
    width: 0;
    height: 0;
    transition: width 0.2s 0.2s, height 0.2s 0.2s, opacity 0.2s;
}
.fadeIn {
    opacity: 1;
    transition: width 0.2s, height 0.2s, opacity 0.5s 0.2s;
}
.resolveChatModalBlur {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
}
.chat-widget .chat-widget-btn svg {
    font-size: 35px;
}

.unseen-message-count-icon-wrapper {
    position: absolute;
    top: 0;
    right: -4px;
    background: rgb(158, 1, 1);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-device-width: 767px) {
    .chat-box-sec {
        max-width: 400px;
        width: 400px;
    }
    .email-modal {
        width: auto;
    }
}

@media (min-device-width: 490px) {
    section.chat-widget {
        width: auto;
    }
}

.vertical-align-middle {
    vertical-align: middle;
}

.status-indicator {
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    z-index: 1;
}
.logo-container {
    display: flex;
    place-content: end;
}

.status-online {
    background-color: #3aaf50;
}
.status-online-text {
    color: #3aaf50;
}
.status-offline {
    background-color:#c22a2a;
}
.status-offline-text {
    color:#c22a2a;
}
.widget-icon {
    width: 35px;
    height: 30px;
}
.margin-right {
    margin-right: 15px !important;
}

.margin-top {
    margin-top: 6px !important;
}
.custom-btn .email-btn.send:disabled {
    border-radius: 10px;
    background-color: #84868d !important;
    border-width: 0 !important;  
}
.field-container {
    display: flex;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #c6cdd3;
    background-color: #fff;
    padding-left: 8px;
    transition: 0.3s;
}
.field-selected {
    transition: 0.3s;
    border-color: #2d4c62 !important;
}
.font-15px {
    font-size: 15px;
}
.lda-text-gray {
    transition: 0.3s;
    color: rgb(138, 138, 138);
}