/*====== 
Fonts 
======*/
@import url("https://fonts.googleapis.com/css?family=Nunito:200,300,400,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900&display=swap");
/*================== 
Generic
====================*/
.rounded-circle {
    border-radius: 50%;
}

.round-img-size {
    width: 4.2rem;
    height: 4.2rem;
    -o-object-fit: cover;
    object-fit: cover;
}
/*===================
 App View 
 ====================*/

#chat-app {
    height: 100%;
}

#chat-app .chatbox-container {
    position: absolute;
    top: 2rem;
    right: 2rem;
    bottom: 4rem;
    left: 2rem;
    display: block;
    float: right;
}

#chat-app .chatbox-container-integrated {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

#chat-app .chatbox-container .chatbox-wrapper-smooth {
    z-index: 1;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 1.6rem 1.8rem rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 1.6rem 1.8rem rgba(0, 0, 0, 0.2) !important;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    background: rgba(255, 255, 255, 1);
}

#chat-app .chatbox-container .chatbox-wrapper-smooth-integrated {
    border-radius: 0;
}
#chat-app .chatbox-wrapper-smooth .chatbox-content-wrapper {
    width: 100%;
    height: 100%;
}

#chat-app .chatbox-content-wrapper .chat-header-wrapper-smooth {
    z-index: 6;
    position: relative;
    width: 100%;
    overflow: hidden;
    float: left;
}

#chat-app .chatbox-content-wrapper .chat-header-smooth {
    height: 6rem;
    z-index: 6;
    width: 100%;
    padding: 0 3.5rem 0 1.5rem;
}

#chat-app .chatbox-content-wrapper .chat-header-smooth.widget-intergrate {
    padding: 0 1.5rem 0 3.5rem;
}

#chat-app .chatbox-content-wrapper .chat-header-smooth .company-name-wrapper {
    float: right;
    height: 100%;
    margin: 0;
    overflow: hidden !important;
    position: relative;
    text-overflow: ellipsis;
    user-select: none;
    width: 85%;
    white-space: nowrap;
}

#chat-app .chatbox-content-wrapper .chat-header-smooth .company-name-wrapper-integrated {
    text-align: center;
    width: 80%;
}

#chat-app .chat-header-smooth .company-name-wrapper .company-name {
    cursor: pointer;
    display: inline-block;
    margin-top: 2.1rem;
    font-size: 1.6rem;
    font-weight: 600;
}

#chat-app .chat-header-smooth .dotted-overflow-wrapper {
    margin-top: 2.3rem;
    white-space: nowrap;
    overflow: hidden !important;
    width: 15%;
    float: left;
    user-select: none;
}

#chat-app .chat-header-smooth .right-dotted-overflow-wrapper {
    float: right;
    text-align: right;
    width: 20%;
}

#chat-app .chat-header-smooth .dotted-overflow {
    cursor: pointer;
    display: inline-block;
    position: relative;
}

#chat-app .chat-header-smooth .dotted-overflow .dot {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-right: 1px;
    background: #ffffff;
}

#chat-app .chat-header-wrapper-smooth .agent-bar-wrapper {
    z-index: 6;
    position: relative;
    width: 100%;
    overflow: hidden;
    float: left;
}
#chat-app .chat-header-wrapper-smooth .agent-bar-wrapper .agent-bar {
    opacity: 0.95;
    height: 6rem;
    z-index: 6;
    width: 100%;
    padding: 0 3.5rem 0 10.5rem;
}

#chat-app .chat-header-wrapper-smooth .agent-bar .agent-profile-picture-wrapper {
    position: absolute;
    width: 7rem;
    height: 100%;
    left: 3.5rem;
    top: 0;
    /* margin-top: 8px; */
}

#chat-app .chat-header-wrapper-smooth .agent-bar .agent-profile-picture {
    width: 5rem;
    height: 5rem;
    display: block;
    position: relative;
}

#chat-app .chat-header-wrapper-smooth .agent-bar .avatar-wrapper {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 100%;
    overflow: hidden;
}

#chat-app .chat-header-wrapper-smooth .agent-bar .avatar {
    height: 100%;
}

#chat-app .chat-header-wrapper-smooth .agent-bar .avatar-wrapper img {
    display: block;
    background: transparent !important;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
#chat-app .chat-header-wrapper-smooth .agent-bar .agent-details {
    position: relative;
}
#chat-app .chat-header-wrapper-smooth .agent-bar .agent-name {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-transform: capitalize;
    margin-top: 0.5rem;
    width: 100%;
    float: left;
    font-size: 1.8rem;
    font-weight: 500;
}

#chat-app .chat-header-wrapper-smooth .agent-bar .agent-title {
    font-size: 1.4rem;
    line-height: 1.5rem;
    width: 100%;
    opacity: 0.7;
    float: left;
    font-weight: 300;
}

#chat-app .overflow-menu {
    top: 3rem;
    position: absolute;
    height: 4.2rem;
    box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 1.5rem,
        rgba(101, 119, 134, 0.15) 0px 0px 0.3rem 0.1rem;
    border-radius: 0.4rem;
    background: white;
    z-index: 99;
    display: none;
}

#chat-app .right-overflow-menu {
    top: 4rem;
    right: 3rem;
}

#chat-app .left-overflow-menu {
    left: 3rem;
}

#chat-app .overflow-menu.show {
    display: initial;
}

#chat-app .overflow-menu .overflow-content {
    cursor: pointer;
    user-select: none;
    font-size: 1.4rem;
    font-weight: 400;
    font-family: "Nunito", sans-serif;
    letter-spacing: 0.6px;
    padding: 1em;
    color: rgb(100, 100, 100);
}

#chat-app .overflow-menu .overflow-content.disabled {
    opacity: 0.5;
    pointer-events: none;
}

#chat-app .chat-header-smooth .chat-close {
    cursor: pointer;
    z-index: 8;
    position: absolute;
    display: block;
    width: 2rem;
    height: 2rem;
    right: 1.3rem;
    top: 2.3rem;
    padding: 0.3rem;
    border-radius: 100%;
}

#chat-app .chat-header-smooth .chat-back-icon {
    cursor: pointer;
    z-index: 8;
    position: absolute;
    display: block;
    width: 2rem;
    height: 2rem;
    left: 1.3rem;
    top: 2.3rem;
    padding: 0.3rem;
}

#chat-app .chat-content {
    float: left;
    position: relative;
    width: 100%;
}

#chat-app .chat-content .conversation {
    float: left;
    position: relative;
    width: 100%;
    height: calc(100% - 6rem);
    min-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 2rem 0 1rem 0 !important;
}

#chat-app .chat-content .conversation .conversation-wrapper {
    width: 100%;
}

#chat-app .conversation .message-loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-style: italic;
    text-transform: capitalize;
    color: #000000ba;
}

#chat-app .conversation .conversation-end-btn {
    position: fixed;
    bottom: 7.7rem;
    right: 0.7rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 0.3rem 0.5rem -0.1rem rgba(0, 0, 0, 0.06),
        -0.1rem 0.6rem 1rem 0px rgba(0, 0, 0, 0.06), 0px 0.1rem 1.8rem 0px rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#chat-app .conversation .conversation-end-btn .text {
    background: orange;
    position: absolute;
    color: #fff;
    font-size: 0.8rem;
    padding: 0.2rem;
    min-width: 1.5rem;
    border-radius: 50%;
    min-height: 1.5rem;
    text-align: center;
    right: -3px;
    top: -2px;
}

#chat-app .conversation .conversation-end-btn:hover {
    cursor: pointer;
}

#chat-app .conversation .conversation-end-btn .text {
    color: #fff;
}

/* #chat-app .conversation .image-preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 6rem;
    min-height: 10rem;
    background: aliceblue;
    width: 100%;
} */

#chat-app .renderUploadFile .image-preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 0px;
    width: 100%;
    max-width: 120px;
    overflow: hidden;
    background: aliceblue;
}

#chat-app .renderUploadFile .image-preview-container.file {
    background: transparent;
    width: auto;
}

#chat-app .renderUploadFile .image-preview-container .file-preview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #eeeeee;
    border-radius: 1.6rem;
    padding: 1.8rem 1.4rem;
    position: relative;
    min-width: 9.5rem;
    min-height: 9rem;
}

.image-preview-container .file-preview .file-name {
    margin-top: 0.5rem;
    max-width: 6.4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* #chat-app .conversation .image-preview-container .image-preview {
    max-width: 12.5rem;
    max-height: 9rem;
    object-fit: contain;
} */

#chat-app .renderUploadFile .image-preview-container .image-preview {
    max-width: 100%;
    object-fit: contain;
    max-height: 150px;
}

#chat-app .renderUploadFile .image-preview-container .image-preview.file {
    max-width: 5.5rem;
    max-height: 3.3rem;
    object-fit: contain;
}

/* #chat-app .conversation .image-preview-container .cross-icon {
    position: absolute;
    right: 1.2rem;
    top: 1rem;
    filter: brightness(0.5);
    cursor: pointer;
} */

#chat-app .renderUploadFile .image-preview-container .cross-icon {
    position: absolute;
    right: 0.2rem;
    top: 0.4rem;
    -webkit-filter: brightness(0.5);
    filter: brightness(1);
    cursor: pointer;
    padding: 3px;
    width: 12px;
    background: #000;
}

#chat-app .renderUploadFile .image-preview-container .cross-icon.file {
    right: 0.6rem;
    top: 0.8rem;
}

#chat-app .conversation .message-loading-top {
    width: 100%;
    position: absolute;
    top: 0.4rem;
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    font-style: italic;
    text-transform: capitalize;
    color: #000000ba;
    z-index: 999;
}

#chat-app .chat-content .conversation .conversation-wrapper .reply {
    width: 100%;
    /* float: left; */
    clear: both;
    margin-bottom: 0rem;
    padding: 0 2rem;
    position: relative;
}

#chat-app .chat-content .conversation .conversation-wrapper .reply .reply-date {
    clear: both;
    float: left;
    font-size: 1.1rem;
    margin-bottom: 0.6rem;
    margin-top: 0.6rem;
    text-align: center;
    width: 100%;
}

#chat-app .chat-content .conversation .conversation-wrapper .reply .reply-note {
    clear: both;
    float: left;
    font-style: italic;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
}

#chat-app .chat-content .conversation .conversation-wrapper .reply .reply-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 5px 5px 5px 15px;
}

#chat-app
    .chat-content
    .conversation
    .conversation-wrapper
    .reply
    .reply-wrapper
    .reply-content.admin,
#chat-app
    .chat-content
    .conversation
    .conversation-wrapper
    .reply
    .reply-wrapper
    .reply-content.user {
    width: 70%;
    min-width: 23rem;
    max-width: 50rem;
}

#chat-app
    .chat-content
    .conversation
    .conversation-wrapper
    .reply
    .reply-wrapper
    .reply-content.user
    .chat-message {
    max-width: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
    border: 1px solid transparent;
    display: block;
    padding: 0.7rem 1.4rem;
    border-radius: 1.9rem;
    float: right;
    text-align: left;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.9rem;
}

#chat-app
    .chat-content
    .conversation
    .conversation-wrapper
    .reply
    .reply-wrapper
    .reply-content.admin
    .chat-message.image,
#chat-app
    .chat-content
    .conversation
    .conversation-wrapper
    .reply
    .reply-wrapper
    .reply-content.user
    .chat-message.image {
    line-height: 1.1rem;
    padding: 0.7rem;
}

.imgMessageWithChat {
    padding: 0 !important;
    border-radius: 0 !important;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    /* margin-bottom: 1.2rem; */
}

.imgMessage {
    padding: 0.5rem !important;
    height: 80px;
    width: 80px;
}

.chat-message .chat-message-image {
    width: 80px;
    height: 80px;
    width: auto;
    max-width: 200px;
}

.chat-message .chat-message-image-content {
    width: 80px;
    height: 80px;
    width: auto;
    max-width: 200px;
}

.chat-message .chat-message-document {
    /* width: 100%;
    height: 100%; */
    width: 50px;
    height: 50px;
}

.chat-message .chat-message-document-content {
    width: 50px;
    height: 50px;
}

.chat-message .chat-message-attachment {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-message .chat-message-attachment img {
    max-height: 1.5rem;
    margin-right: 0.5rem;
}

.chat-message .chat-message-attachment a {
    color: #fff;
    word-break: break-all;
}

#chat-app
    .chat-content
    .conversation
    .conversation-wrapper
    .reply
    .reply-wrapper
    .reply-content.admin
    .chat-message {
    min-height: 3.5rem;
    max-width: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
    border: 1px solid transparent;
    display: block;
    padding: 0.7rem 1.4rem;
    border-radius: 1.9rem;
    float: left;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.9rem;
}

.chat-message .message-caption {
    padding-top: 8px;
    padding-left: 5px;
    line-height: 1.2;
}
#chat-app
    .chat-content
    .conversation
    .conversation-wrapper
    .reply
    .reply-wrapper
    .reply-content.user {
    float: right;
}

#chat-app
    .chat-content
    .conversation
    .conversation-wrapper
    .reply
    .reply-wrapper
    .reply-content.admin {
    float: left;
}

#chat-app .chat-content .conversation .conversation-wrapper .reply .reply-wrapper .user-name {
    margin-left: 1rem;
    padding: 0.5rem 0;
    font-size: 1rem;
    letter-spacing: 0.2px;
    text-transform: capitalize;
}
#chat-app .chat-content .conversation .conversation-wrapper .reply .reply-wrapper .reply-content {
    min-height: 4rem;
}

#chat-app .chat-content .chat-field {
    z-index: 3;
    position: relative;
    height: 6rem;
    width: 100%;
    float: left;
    background: rgb(255, 255, 255);
    opacity: 0.6;
}

.chat-field form {
    display: inline;
}

.chat-field textarea {
    color: rgb(0, 0, 0);
    font-family: inherit;
    font-size: 1.4em;
    font-weight: 300;
    padding: 2rem 0 0.4rem 2.5rem;
    background: transparent;
    width: calc(100% - 70px);
    height: 100%;
    border: 0;
    float: left;
    resize: none;
}

.chat-field textarea::placeholder {
    color: inherit;
    font-size: inherit;
}

#chat-app .chat-content .chat-field .actions {
    display: flex;
    justify-content: space-between;
    float: right;
    height: 100%;
    padding: 2.2rem 2rem 2.2px 0px;
    width: 7rem;
    overflow: hidden;
}

#chat-app .chat-content .chat-field .actions .action-btn {
    cursor: pointer;
    position: relative;
    display: block;
    background: transparent;
    width: 2rem;
    border: none;
    height: 2rem;
}

#chat-app .chat-content .chat-field .actions .action-btn.send {
    opacity: 0.5;
}

#chat-app .chat-content .chat-field .actions .action-btn.send.allowed {
    opacity: 1;
}

#chat-app .chat-content .chat-field .actions .action-btn.add span {
    position: absolute;
}

#chat-app .chat-content .chat-field .actions .action-btn.add:hover {
    cursor: pointer;
}

#chat-app .action-btn.add input[type="file"]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
}

#chat-app .chat-content .chat-field .actions .action-btn.add span input {
    width: 2rem;
    height: 2rem;
    opacity: 0;
}
.agent-bottom-message {
    display: flex;
    justify-content: space-between;
    text-align: center;
    position: absolute;
    bottom: 1.2rem;
    margin: 0 1.5rem;
    width: calc(100% - 3rem);
}

.agent-bottom-message p {
    align-self: center;
    color: rgba(0, 0, 0, 0.7);
    font-size: 1.5rem;
}

.agent-bottom-message {
    display: flex;
    justify-content: space-between;
    text-align: center;
    position: absolute;
    bottom: 1.2rem;
    margin: 0 1.5rem;
    width: calc(100% - 3rem);
}

.agent-bottom-message p {
    align-self: center;
    color: rgba(0, 0, 0, 0.7);
    font-size: 1.5rem;
}

#chat-app .chat-button-wrapper {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
}
#chat-app .chat-button-wrapper.bar {
    width: 28rem;
    height: 5.5rem;
}

#chat-app .chat-button {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 100%;
    cursor: pointer;
    -webkit-box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
    background: #f39c12;
    color: rgb(255, 255, 255);
}

#chat-app .chat-button-wrapper.bar .chat-button {
    border-radius: 1rem;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
}

#chat-app .chat-button-wrapper .chat-button span {
    display: none;
}

#chat-app .chat-button-wrapper.bar .chat-button span {
    display: inline-block;
    font-size: 1.8rem;
}

#chat-app .chat-button img {
    margin: 12px 0 0 13px;
}

#chat-app .chat-button-wrapper.bar .chat-button img {
    margin: 0;
}

.email-modal-backrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(247, 247, 247, 0.8);
    z-index: 9;
}

.email-modal-backrop.fixed {
    position: fixed;
}

.email-modal-backrop.hide {
    display: none;
}

.email-modal-backrop.email-form {
    background: transparent;
}

.email-modal {
    box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 1.5rem,
        rgba(101, 119, 134, 0.15) 0px 0px 0.3rem 0.1rem;
    background: #f6f7f8;
    left: 8px;
    border-radius: 1.5rem;
    z-index: 99;
    width: 90%;
}

.email-modal.email-form {
    min-height: 100%;
    background: rgba(255, 255, 255, 1);
}

.email-modal .email-modal-content-wrapper {
    position: relative;
    /* width: 25rem; */
}

.email-modal .email-modal-content {
    width: 100%;
    padding: 0 1rem 1.2rem 1rem;;
}

.email-modal .email-modal-content-heading {
    background-color: #22385b;
    padding: 1rem 0 1rem 1.5rem;
    margin-bottom: 1em;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
}

.email-modal .email-modal-content-heading h1 {
    color: #ffffff;
    display: flex !important;
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    font-size: 1.4em;
    text-align: left;
    /* margin: 1.5rem 0 1.5rem 0; */
    letter-spacing: 0.6px;
}

.email-modal .email-modal-content .email-modal-input {
    margin-bottom: 0.5rem;
}

.email-modal .email-modal-content .email-modal-input .error {
    border: 1px solid red;
}

.email-modal .email-modal-content .email-modal-input input,
.email-modal .email-modal-content .email-modal-input textarea {
    z-index: 1;
    display: block;
    overflow: hidden;
    box-sizing: border-box;
    margin: 0;
    padding: 0 11px;
    width: 100%;
    height: 3rem;
    outline: 0;
    border: 1px solid #c6cdd3;
    border-radius: 2px;
    background-color: #fff;
    color: #000000;
    vertical-align: middle;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Nunito", sans-serif;
    font-size: 1.5em;
    font-weight: 400;
    transition: border 0.3s ease, background-color 0.3s ease, color 0.3s ease, padding 0.3s ease;
}

.email-modal .email-modal-content .email-modal-input textarea {
    height: auto;
    padding: 0.8px 1.1rem;
}

.email-modal .email-modal-content .email-modal-input input:focus,
.email-modal .email-modal-content .email-modal-input textarea:focus {
    /* Dynamic */
    /* border: 1px solid #22385b; */
    border: 1px solid #2d4c62;
}

.email-modal-btn-wrapper {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    /* margin: 0.8px 0 1.5rem 0; */
}

.email-modal-btn-wrapper .email-btn {
    width: auto;
    border-radius: 0.2rem;
    letter-spacing: 0.6px;
    /* text-transform: uppercase; */
    overflow: hidden;
    margin: 0 0.4rem;
    max-width: 100%;
}

.email-modal-btn-wrapper .email-btn.send {
    background: #f39c12;
    border: 1px solid #f39c12;
    padding: 0 1.5rem;
    height: 2.5em;
    line-height: 0;
}
.email-modal-btn-wrapper .email-btn.send:disabled {
    opacity: 0.5;
}

.email-modal-btn-wrapper .email-btn.cancel {
    background: lightgray;
    border: 1px solid lightgray;
}

.agent-chat-closed {
    font-size: 15px;
    text-align: center;
    color: #84868d;
}

.btn {
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 1.3em;
    font-family: "Nunito", sans-serif;
    padding: 0 3.6rem;
    height: 3em;
    line-height: 3em;
    transition: background-color 0.3s, color 0.3s;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    outline: 0;
}

.btn-chat-start {
    background: rgb(243, 156, 18);
    border: 1px solid rgb(243, 156, 18);
    border-radius: 0.5rem;
    padding: 0 1.5rem;
    height: 2.8em;
    line-height: 2.8em;
}

.typing-animation {
    position: relative;
}

.typing-animation .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 3px;
    background: #748495;
    animation: typingAnimation 1.3s linear infinite;
}

.typing-animation .dot:nth-child(2) {
    animation-delay: -1.1s;
}

.typing-animation .dot:nth-child(3) {
    animation-delay: -0.9s;
}

.reply .read-bubble-container {
    top: 100%;
    position: absolute;
    right: 0px;
    display: flex;
}

.read-bubble-container .read-bubble {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    margin-right: 6px;
}

.read-bubble-container .read-bubble img {
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.notify-dot {
    display: flex;
    text-align: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px;
    max-width: 30px;
    max-height: 20px;
    width: 10px;
    height: 10px;
    font-weight: 600;
    line-height: 16px;
    color: #fff;
    white-space: nowrap;
    vertical-align: baseline;
    font-size: 1.3rem;
    border-radius: 50%;
    border: 1.5px solid;
    border-color: #fff;
    position: absolute;
    right: 75%;
    top: -1px;
}

.chat-button-wrapper.bar .notify-dot {
    right: 95%;
    top: -9px;
}

@keyframes typingAnimation {
    0%,
    60%,
    100% {
        transform: initial;
    }
    30% {
        transform: translateY(-8px);
    }
}

@media only screen and (max-width: 280px) {
    html {
        font-size: 8px;
    }
    #swift-sales-widget {
        width: 35rem;
        height: 56rem;
    }
    /* #chat-app .chat-content .conversation {
        min-width: 248px;
    } */
    .typing-animation .dot {
        width: 4px;
        height: 4px;
    }
    #chat-app .chat-content .chat-field .actions .action-btn svg {
        width: 14px;
        height: 14px;
    }
    #chat-app .chat-button-wrapper.bar .chat-button img {
        width: 30px;
    }
}

@media only screen and (max-width: 90px) {
    #chat-app .chat-button-wrapper {
        bottom: 16px;
        right: 16px;
        width: 48px;
        height: 48px;
    }

    #chat-app .chat-button img {
        width: 30px;
        margin: 9.6px 0 0 10.4px;
    }
}

@media (min-device-width: 490px) {
    #chat-app .chat-content .conversation {
        min-width: 32rem;
    }
}
.width-100 {
    width: 100%
}
.typing-indicator {
    position: sticky !important;
    bottom: 0;
}
.form-container {
    position: absolute;
    z-index: 1;
    width: inherit;
    padding: 10px 0 15px 15px;
}
.padding-0 {
    padding: 0 !important;
}
.bottom-0 {
    bottom: 0;
}
.custom-input-field input {
    border-width: 0 !important;
    padding: 0 11px 0 2px !important;
}


#chat-app .chat-content .conversation {
    height: 364px;
}

@media (max-device-width: 575px) {
    #chat-app .chat-content .conversation {
        height: 260px;
    }
}

.typing-hint {
    color: #3aaf50;
    font-size: 12px;
    padding-left: 15px;
    padding-bottom: 8px;
    min-height: 25px;
}
